import React, { Component } from 'react';
import ReactDOM from "react-dom";
import {observer} from "mobx-react";

@observer
class Modal extends Component {
    render() {
        const { children, className, iconClass, title } = this.props;

        return ReactDOM.createPortal(
            <div className="modal-cover">
                <div className={"modal " + className}>
                    {title && (
                        <div className="modal-header">
                            <i className={iconClass}/> {title}
                        </div>
                    )}
                    <div className="modal-content">
                        {this.props.children}
                    </div>
                </div>
            </div>,
            document.body,
        );
    }
}

export default Modal;
