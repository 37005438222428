import React from 'react';
import {observer} from 'mobx-react';
import { applySnapshot, getSnapshot } from 'mobx-state-tree';
import {UNITS} from '../stores/ProjectStore';
import NumberFormat, {NumberFormatValues} from "react-number-format";

function UnitInput({unit, currency = false, position = 'right', size = 'full', onChange, value}) {
    const onUserChangeComplete = (value) => {
        //NOTE that we can't use onValueChange because that fires on user changes as well as
        // changes from state updates which creates circular references from automatic updates
        let floatValue = parseFloat(value.replace(/[^\d.\-]/g, ""));
        onChange({floatValue: isNaN(floatValue) ? 0 : floatValue});
    };

    return (
        <div className={`UnitInput UnitInput--${position} UnitInput--${size} ` + (currency ? 'currency' : '')}>
            <NumberFormat
                value={value}
                thousandSeparator={true}
                onBlur={(e) => {
                    onUserChangeComplete(e.target.value);
                }}
                onKeyDown={(e) => {
                    if (e.keyCode === 13) {  //enter key
                        onUserChangeComplete(e.target.value);
                    }
                }}
            />
            <label htmlFor={unit}>{unit}</label>
        </div>
    );
}

class ProjectSettings extends React.Component {

    componentDidMount() {
        this.setState({
            snapshot: getSnapshot(this.props.store),
        });
    }

    cancel() {
        applySnapshot(this.props.store, this.state.snapshot)
        this.props.closeModal();
    }

    render() {
        const { store, closeModal } = this.props;
        const {project: p, unitsStore: u} = store;
        const currencyUnit = u.currencyUnitsDisplay;

        return (
            <div className="ProjectSettings">
                <div className="settings-row">
                    <div className="settings-column">

                        <div className="settings-group">
                            <h4>Project Units</h4>
                            <select onChange={({target}) => p.setUnitSystem(target.value)}>
                                {Object.keys(UNITS).map(unit => (
                                    <option key={unit} value={unit}>{unit}</option>
                                ))}
                            </select>
                        </div>

                        <div className="settings-group">
                            <h2>Targets - Area</h2>
                            <h4>Target Gross Area</h4>
                            <UnitInput unit={u.squareUnitsDisplay} value={Math.round(p.targetGrossArea)}
                                       onChange={
                                           (e) => {
                                               p.setTargetGrossArea(e.floatValue)
                                           }
                                       }/>

                            <h4>Grossing Factor</h4>
                            <UnitInput unit={'%'} value={p.grossingFactor}
                                       onChange={(e) => p.setGrossingFactor(e.floatValue)}/>

                            <h4>Net Area</h4>
                            <UnitInput unit={u.squareUnitsDisplay} value={Math.round(p.netArea)}
                                       onChange={
                                           (e) => p.setTargetNetArea(e.floatValue)
                                       }/>
                        </div>
                    </div>

                    <div className="settings-column">
                        <h2>Targets - Costs</h2>

                        <h4>Total Project Budget</h4>
                        <UnitInput unit={currencyUnit} position={'left'} value={Math.round(p.totalProjectBudget)}
                                   onChange={(e) => p.setTotalProjectBudget(e.floatValue)}/>

                        <h4>Escalation</h4>
                        <div className="input-group">
                            <UnitInput size={'half'} unit={'%'} value={Math.round(100 * p.escalationRate) / 100}
                                       onChange={(e) => p.setEscalationRate(e.floatValue)}/>
                            <UnitInput size={'half'} unit={'months'} value={p.escalationTime}
                                       onChange={(e) => p.setEscalationTime(e.floatValue)}/>
                        </div>
                        <div className="input-group">
                            <UnitInput size={'half'} unit={'% (overall)'} value={Math.round(100 * p.escalationPerc) / 100}
                                       onChange={(e) => p.setEscalationPerc(e.floatValue)}/>
                            <UnitInput size={'half'} currency={true} unit={currencyUnit} value={Math.round(p.escalationCost)}
                                       onChange={(e) => p.setEscalationCost(e.floatValue)}/>
                        </div>

                        <h4>Other Costs <em>(Site Work etc.)</em></h4>
                        <UnitInput unit={currencyUnit} position={'left'} value={p.otherCosts}
                                   onChange={(e) => p.setOtherCosts(e.floatValue)}/>

                        <h4>Construction Budget</h4>
                        <UnitInput unit={currencyUnit} position={'left'} value={Math.round(p.constructionBudget)}
                                   onChange={(e) => p.setConstructionBudget(e.floatValue)}/>

                        <h4>Soft Costs</h4>
                        <div className="input-group">
                            <UnitInput size={'half'} unit={'%'} value={Math.round(p.softCostsPerc * 10) / 10}
                                       onChange={(e) => p.setSoftCostsPerc(e.floatValue)}/>
                            <UnitInput size={'half'} currency={true} unit={currencyUnit} value={Math.round(p.softCost)}
                                       onChange={(e) => p.setSoftCost(e.floatValue)}/>
                        </div>

                        <h4>Program Budget</h4>
                        <p className="budget-summary">
                            <div className="input-group">
                                <UnitInput size={'half'} currency={true} unit={currencyUnit} value={Math.round(p.programBudget)}
                                           onChange={(e) => p.setProgramBudget(e.floatValue)}/>
                                <UnitInput size={'half'} unit={currencyUnit + ' / ' + u.squareUnitsDisplay}
                                           value={Math.round(p.costPerUnit)}
                                           onChange={(e) => p.setCostPerUnit(e.floatValue)}/>
                            </div>
                        </p>
                    </div>
                </div>

                <div className="modal-buttons">
                    <button className="cancelButton ss-button nl" onClick={() => this.cancel()}>
                        Cancel
                    </button>

                    <button className="confirmButton ss-button primary" onClick={closeModal}>
                        Save
                    </button>
                </div>
            </div>
        );
    }
};

export default observer(ProjectSettings);
