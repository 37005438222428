import React, {Component} from 'react';
import {observer} from "mobx-react";
import GooglePicker from "../GooglePicker";


@observer
class LoadModal extends Component {
    constructor(props){
        super(props);
        this.state = {
            file: "",
            error: false
        };
    }
    onFileUpload = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');

        reader.onerror = (e) => {
            this.setState({
                file: "",
                error: true
            });
            reader.abort();
        };

        reader.onload = async (e) => {
            let fileJSON = JSON.parse(reader.result);
            if (fileJSON){
                this.props.store.persistStore.loadSnapshot(fileJSON);
            }
        };
        reader.readAsBinaryString(file);
    }

    render() {
        const {closeModal} = this.props;

        return (
            <div className="LoadModal">
                <button className="startButton block ss-button primary" onClick={() => new GooglePicker(store)}>
                    Load Google Drive
                </button>

                <i>Open a file at the root of your Google Drive (this is the most common option)</i>

                <div>
                    <label className="startButton block ss-button primary" htmlFor="fileLoad">
                        Load PRGM File
                    </label>
                    <input id="fileLoad" type="file" name="file" value="" accept="prgm" onChange={this.onFileUpload}/>
                    {this.state.error && <p className="p-md error">File upload error</p>}
                </div>

                <i>Once selected from your local computer drive, the file will be automatically uploaded into your Google Drive.</i>

                <div className="modal-buttons">
                    <button className="cancelButton block ss-button lt-grey" onClick={closeModal}>
                        Cancel
                    </button>
                </div>
            </div>
        );
    }
}

export default LoadModal;
