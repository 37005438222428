import React, { Component } from 'react';
import {observer} from "mobx-react";

@observer
class LoadImageDialog extends Component {
    constructor(props){
        super(props);
        this.state = {
            imageName: "",
            imageURL: "",
            error: false
        };
    }
    onFileUpload = (e) => {
        let file = e.target.files[0];
        let reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');

        reader.onerror = (e) => {
            this.setState({
                imageName: "",
                imageURL: "",
                error: true
            });
            reader.abort();
        };

        reader.onload = async (e) => {
            let imageHandler = this.props.store.app.imageHandler;
            const arrayBuffer = reader.result;
            if (imageHandler) {
                const imageObj = await imageHandler.processImage(arrayBuffer, file.type.split('/')[1]);
                this.setState({
                    imageName: file.name,
                    imageURL: imageObj
                })
            }

        };
        reader.readAsArrayBuffer(file);
    };
    removeImage = () => {
        this.setState({
            imageName: "",
            imageURL: ""
        });
    };
    onSave = () => {
        if (this.state.imageURL){
            let image = new Image();
            image.onload = () => {
                this.props.store.stage.setImage(this.state.imageName, this.state.imageURL, [image.width, image.height]);
                this.props.closeModal();
            };
            image.src = this.state.imageURL;
        } else {
            this.props.store.stage.setImage("", "", [0, 0]);
            this.props.closeModal();
        }
    };
    componentDidMount(){
        this.setState({
            imageName: this.props.store.stage.imageName,
            imageURL: this.props.store.stage.imageURL
        });
    }
    render() {
        return (
            <div className="LoadImageDialog">
                <p>Import an underlay image to draw over.</p>

                {!this.state.imageName &&
                <div>
                    <label className="ss-button block nl" htmlFor="imageLoad">
                        Choose File
                    </label>
                    <input id="imageLoad" type="file" name="file" value="" accept="image/*" onChange={this.onFileUpload}/>
                    {this.state.error && <p className="p-md error">File upload error</p>}
                </div>}

                {this.state.imageName &&
                <div className="image">
                    <span>{this.state.imageName}</span> <i onClick={this.removeImage} className="remove retina-theessentials-2544" />
                </div>}

                <div className="modal-buttons">
                    <button className="cancelButton ss-button nl" onClick={this.props.closeModal}>Cancel</button>
                    <button className="confirmButton ss-button primary" onClick={this.onSave}>Save</button>
                </div>
            </div>
        );
    }
}

export default LoadImageDialog;
